import React from "react"
import Layout from "../components/Layout";
import '../styles/global.css';

export default function ForResearchers() {
    return (
        <React.Fragment>
            <Layout>
            <h1 className='welcomeHeading'>For Physicians and Researchers</h1>
            <br />
        <p className='physiciansMatter'>MAP COPD, an observational research study being conducted at the University of Michigan, 
            aims to collect health-related information, diet and symptom questionnaires, a computed tomography of the chest, 
            pulmonary function tests (spirometry), blood specimens and nasal swabs to better understand the early stages of chronic 
            obstructive pulmonary disease (COPD). Participants will also be contributing to&nbsp; 
            <a href='https://research.medicine.umich.edu/our-units/central-biorepository' target="_blank" rel="noopener noreferrer"  className="linkInParagraph">University of Michigan Central Biorepository</a> and <a href='https://precisionhealth.umich.edu/our-research/michigangenomics/' target="_blank" rel="noopener noreferrer"  className="linkInParagraph">Michigan Genomics Initiative</a>.
            <br /><br />
            MAP COPD is funded by the Alfred Taubman Medical Research Institure at the University of Michigan in partnership with the COPD Foundation. 
            <br /><br />
            If you are interested in collaborating in this study, please contact principal investigator, MeiLan Han MD, MS at <a href="tel:8443341234" className='callLink'>(844) 334-1234</a>.
        </p>
        </Layout>
        </React.Fragment>
    )
}